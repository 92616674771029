<template>
  <div class="page" :class="{'is-mobile' : $vuetify.breakpoint.mobile}">
    <section class="header">
      <slot name="header">
        <v-toolbar flat height="60px" color="grey lighten-4" v-if="!$vuetify.breakpoint.mobile">
          <v-tooltip top right>
            <template v-slot:activator="{on}">
              <div v-on="on" :class="searchMenuOpened ? 'filter-toggle-button opened' : 'filter-toggle-button'"
                   @click="searchMenuOpened = !searchMenuOpened">
                <v-icon :color="searchMenuOpened ? 'primary' : 'grey'">mdi-filter</v-icon>
              </div>
            </template>
            <span>
              Filtros
            </span>
          </v-tooltip>
          <div class="actions d-flex flex-grow-1">
            <slot name="actions" v-if="!$vuetify.breakpoint.mobile"/>
          </div>
          <v-spacer v-if="$listeners['click:add'] != null || $slots['actions:append'] != null"/>
          <slot name="actions:extras" v-if="!$vuetify.breakpoint.mobile"/>
          <template v-if="$listeners['click:add'] != null">
            <v-divider vertical class="px-3"/>
            <v-btn text tile color="primary"
                   class="ml-4"
                   @click="$emit('click:add')">
              <v-icon>mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </template>
          <v-spacer v-if="$listeners['click:config'] != null"/>
          <template v-if="$listeners['click:config'] != null">
            <v-divider vertical class="px-3"/>
            <v-btn text tile color="primary"
                   class="ml-4"
                   @click="$emit('click:config')">
              <v-icon>mdi-cog</v-icon>
              Configurar
            </v-btn>
          </template>
        </v-toolbar>
      </slot>
      <v-toolbar class="overflow-x-auto" style="max-width: 100vw" flat height="60px" color="grey lighten-4"
                 v-if="$vuetify.breakpoint.mobile && $slots['actions:extras'] != null">
        <slot name="actions:extras"/>
      </v-toolbar>
      <v-divider class="hidden-xs-only"/>
    </section>

    <div class="d-flex page-pre-content" :class="{'is-mobile': $vuetify.breakpoint.mobile}"
         style="background-color: #f5f5f5">
      <section :class="searchMenuOpened ? 'filters opened' : 'filters'" v-if="!$vuetify.breakpoint.mobile">
        <h3 class="ma-2">Filtros</h3>
        <v-divider class="mx-2"/>
        <div class="pa-2">
          <div @keypress.enter="submitSearch" v-for="(col, index) of searchColumns" :key="'col_'+index">
            <div v-if="col.group != null">
              <h4 class="my-1">{{ col.group }}</h4>
              <app-search-field v-for="(innerCol, index) of col.columns" class="mb-2" :key="'innercol_'+index"
                                v-model="searchValuesInternal[innerCol.column]"
                                :definition="innerCol"/>
              <v-divider class="my-3"/>
            </div>
            <app-search-field v-else class="mb-2"
                              v-model="searchValuesInternal[col.column]"
                              :definition="col"/>
          </div>
          <v-btn block large color="primary" @click="submitSearch">Buscar</v-btn>
        </div>
      </section>
      <v-dialog v-else fullscreen scrollable
                @click="searchMenuOpened = false"
                small
                v-model="searchMenuOpened">
        <v-card>
          <v-card-title>
            Filtros
            <v-spacer></v-spacer>
            <v-btn icon @click="searchMenuOpened = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">
            <v-form @submit.prevent="submitSearch">
              <div v-for="(col,index) of searchColumns" :key="'col_2'+index">
                <div v-if="col.group != null">
                  <h4 class="my-1">{{ col.group }}</h4>
                  <app-search-field v-for="(innerCol, index) of col.columns" class="mb-2" :key="'innercol2_'+index"
                                    v-model="searchValuesInternal[innerCol.column]"
                                    :definition="innerCol"/>
                  <v-divider class="my-3"/>
                </div>
                <app-search-field v-else class="mb-2"
                                  :filled="true"
                                  v-model="searchValuesInternal[col.column]"
                                  :definition="col"/>
              </div>
            </v-form>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn block large color="primary" @click="submitSearch">Buscar</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
      <section class="content" :style="{'--append-height': $slots['actions:extras'] != null ? '60px' : '0px'}">
        <slot/>
      </section>
    </div>

    <v-btn icon fixed top right
           :style="{'z-index': 10, 'right': $listeners['click:add'] || $listeners['click:config'] ?  '50px' : '10px'}"
           @click="openSearchMenu"
           color="primary"
           v-if="$vuetify.breakpoint.mobile">
      <v-icon>mdi-filter</v-icon>
    </v-btn>

    <v-btn color="primary" icon fixed top right
           @click="$emit('click:add')"
           style="z-index: 10"
           v-if="$vuetify.breakpoint.mobile && $listeners['click:add'] != null">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn color="primary" icon fixed top right
           @click="$emit('click:config')"
           style="z-index: 10"
           v-if="$vuetify.breakpoint.mobile && $listeners['click:config'] != null">
      <v-icon>mdi-cog</v-icon>
    </v-btn>
  </div>
</template>

<script>

import AppSearchField from "../components/app/AppSearchField";

/**
 * Base View Component
 *
 * @emit click:add on click in add button
 * @emits update:search-column
 * @emits update:search-value
 */
export default {
  name: "BaseView",
  components: {AppSearchField},
  props: {
    /** @type {import('vue').PropOptions<{name: string, column: string, type: 'number' | 'text', prefix?: string}>}  */
    searchColumns: {
      type: Array,
    }
  },
  data() {
    return {
      searchValuesInternal: {},
      searchMenuOpened: false,
      selectedSearchColumn: this.searchColumns?.find(({column}) => column === this.searchColumn),
      search: '',
    }
  },
  computed: {
    hasSearchColumns() {
      return this.searchColumns?.length > 0;
    },
  },
  asyncComputed: {
    async searchColumnItems() {
      const fn = this.selectedSearchColumn?.optionsFactory;

      if (typeof fn === 'function') {
        return fn();
      }
      return [];
    }
  },
  methods: {
    submitSearch() {
      this.$emit('update:search-values', {...this.searchValuesInternal});
      if (this.$vuetify.breakpoint.mobile) {
        this.searchMenuOpened = false;
      }
    },
    openSearchMenu() {
      this.searchMenuOpened = true;
    }
  },
  mounted() {
    const setColumnDefaultValues = () => {
      if (this.searchColumns?.length > 0) {
        for (const sc of this.searchColumns) {

          if (sc.hasOwnProperty('column')) {
            if (sc.defaultValue != null) {
              this.searchValuesInternal[sc.column] = sc.defaultValue;
            }
          } else if (sc.hasOwnProperty('columns')) {
            for (const col of sc.columns) {
              this.searchValuesInternal[col.column] = col.defaultValue

            }
          }

        }
      }
    }

    setColumnDefaultValues();
  }
}
</script>

<style scoped lang="scss">

.page.is-mobile {
  .content {
    max-height: calc(100vh - (60px + var(--append-height)));
  }

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - (97px + var(--append-height)));
    }
  }
}


.content {
  overflow: auto;
  max-height: calc(100vh - 120px);
  flex: 1;

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 157px);
    }
  }
}


.filters {
  width: 0;
  overflow: auto;
  height: calc(100vh - 120px);
  background-color: rgba(0, 0, 0, 0.1);

  transition: width 1s ease 0s;

  &.opened {
    border-right: 1px solid #dedede;
    width: 350px;
  }
}

.filter-toggle-button {
  border-right: 1px solid #dedede;
  height: 60px;
  width: 60px;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s ease 0s;
  cursor: pointer;

  &.opened, &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.actions {
  margin-left: 44px;
}

</style>